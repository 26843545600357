import { useIntl } from "react-intl";
import { MenuItem } from "./MenuItem";
import { MenuInnerWithSub } from "./MenuInnerWithSub";
import { MegaMenu } from "./MegaMenu";
import { useAuth } from "../../../../../app/modules/auth";
import { useLocation } from "react-router-dom";
import { MenuInnerWithPrimaryMenu } from "./MenuInnerWithPrimaryMenu";
import { usePermissionValues } from "../../../../../app/commonComponents/useCheckPermission";
import { MegaDash } from "./MegaDash";
import { MegaAudioDash } from "./MegaAudioDash";

export function MenuInner() {
  const intl = useIntl();

  const location = useLocation();

  const { currentUser } = useAuth();

  const {
    permissionVideos,
    permissionSeries,
    permissionLiveTV,
    permissionLibrary,
    permissionEncoding,
    permissionVideoLayout,
    permissionAlbums,
    permissionPodcasts,
    permissionPlayist,
    permissionAudioGenres,
    permissionAudioLayout,
    permissionCategories,
    permissionLanguage,
    permissionGenres,
    permissionProdHouse,
    permissionCasts,
    permissionGeoRestriction,
    permissionContentPartner,
    permissionAdminUsers,
    permissionRoles,
    permissionBroadcastGroups,
    permissionBroadcastMessages,
    permissionFeedbacks,
    permissionContentAnalystics,
    permissionCustomerAnalystics,
    permissionAudioAnalystics,
    permissionPlans,
    permissionTransaction,
    permissionDashboard,
    permissionGeoFencing,
    permissionUserManagement,
  } = usePermissionValues();

  const permission_Content_Settings =
    permissionCategories.isViewable ||
    permissionLanguage.isViewable ||
    permissionGenres.isViewable ||
    permissionProdHouse.isViewable ||
    permissionCasts.isViewable ||
    permissionGeoRestriction.isViewable ||
    permissionContentPartner.isViewable;

  const permission_Admin =
    permissionAdminUsers.isViewable || permissionRoles.isViewable;

  const permission_Broadcast =
    permissionBroadcastGroups.isViewable ||
    permissionBroadcastMessages.isViewable;

  // console.log("contentSettings permission:", permission_Admin);

  return (
    <>
      {(location.pathname === "/dashboard" ||
        location.pathname !== "/customers/list") && (
        <>
          {permissionDashboard.isViewable && (
            <MenuItem
              title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
              to="/dashboard"
            />
          )}
          {(permissionVideos.isViewable ||
            permissionSeries.isViewable ||
            permissionLiveTV.isViewable ||
            permissionLibrary.isViewable ||
            permissionVideoLayout.isViewable ||
            permissionEncoding.isViewable) && (
            <MenuInnerWithSub
              title="Video"
              to="/content"
              menuPlacement="bottom-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
              isMega= {true}
            >
              <MegaDash 
              title="Video"
              to="/content"
              menuPlacement="bottom-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
              />
            </MenuInnerWithSub>
          )}
          {(permissionAlbums.isViewable ||
            permissionPodcasts.isViewable ||
            permissionPlayist.isViewable ||
            permissionAudioGenres.isViewable ||
            permissionAudioLayout.isViewable) && (
            <MenuInnerWithSub
              title="Audio"
              to="/audio"
              menuPlacement="bottom-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
              isMega= {true}
            >
              <MegaAudioDash />
            </MenuInnerWithSub>
          )}

          {(permissionPlans.isViewable || permissionTransaction.isViewable) && (
            <MenuInnerWithSub
              title="Monetization"
              to="/monetization"
              menuPlacement="bottom-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
              isMega= {false}
            >
            {permissionPlans.isViewable &&
              <MenuItem
                title="Plans"
                to="/monetization/plans"
                fontIcon="bi bi-card-list"
              />}

            {permissionTransaction.isViewable &&
              <MenuItem
                title="Transactions"
                to="/monetization/transaction"
                fontIcon="bi bi-cash-stack"
              />}
            </MenuInnerWithSub>
          )}
          {(permission_Content_Settings ||
            permission_Admin ||
            permission_Broadcast ||
            permissionUserManagement.isViewable ||
            permissionFeedbacks.isViewable ||
            permissionGeoFencing.isViewable) && (
            <MenuInnerWithSub
              title="Manage"
              to="/manage"
              menuPlacement="bottom-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
              isMega= {false}
            >
              {/* Content Settings */}
            {permission_Content_Settings &&
              <MenuInnerWithSub
                title="Content Settings"
                to="/settcon"
                fontIcon="bi-archive"
                hasArrow={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
            {permissionProdHouse.isViewable &&
                <MenuItem
                  to="/manage/setting-contents/production-company"
                  title="Production House"
                  hasBullet={true}
                />}
            {permissionGenres.isViewable &&
                <MenuItem
                  to="/manage/setting-contents/genre"
                  title="Genre"
                  hasBullet={true}
                />}
            {permissionCasts.isViewable &&
                <MenuItem
                  to="/manage/setting-contents/cast"
                  title="Cast"
                  hasBullet={true}
                />}
            {permissionLanguage.isViewable &&
                <MenuItem
                  to="/manage/setting-contents/language"
                  title="Language"
                  hasBullet={true}
                />}
            {permissionCategories.isViewable &&
                <MenuItem
                  to="manage/setting-contents/category"
                  title="Category"
                  hasBullet={true}
                />}
            {permissionContentPartner.isViewable &&
                <MenuItem
                  to="/manage/setting-contents/content-partner"
                  title="Content Partner"
                  hasBullet={true}
                />}
            {permissionGeoRestriction.isViewable &&
                <MenuItem
                  to="/manage/setting-contents/georestriction"
                  title="Geo Restrictions"
                  hasBullet={true}
                />}
              </MenuInnerWithSub>}

              {/* ADMINS */}
              {permission_Admin && 
              <MenuInnerWithSub
                title="Admins"
                to="/admin"
                fontIcon="bi-sticky"
                hasArrow={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
            {permissionAdminUsers.isViewable &&
                <MenuItem
                  to="/manage/usermanagement/admins"
                  title="Admins"
                  hasBullet={true}
                />}
            {permissionRoles.isViewable &&
                <MenuItem
                  to="/manage/usermanagement/roles"
                  title="Roles"
                  hasBullet={true}
                />}
              </MenuInnerWithSub>}

              {/* BROADCAST */}
              {permission_Broadcast && 
              <MenuInnerWithSub
                title="Notification"
                to="/broadcast"
                fontIcon="bi-layers"
                hasArrow={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
            {permissionBroadcastGroups.isViewable &&
                <MenuItem
                  to="/manage/notification/groups"
                  title="Groups"
                  hasBullet={true}
                />}
            {permissionBroadcastMessages.isViewable &&
                <MenuItem
                  to="/manage/notification/messages"
                  title="Messages"
                  hasBullet={true}
                />}
              </MenuInnerWithSub>}

              {/* USER MANAGEMENT */}
              {permissionUserManagement.isViewable &&
              <MenuItem
                title="User Management"
                to="/manage/users/list"
                fontIcon="bi-person"
              />}
              {/* Feedback */}
            {permissionFeedbacks.isViewable &&
              <MenuItem
                title="Feedback"
                to="/manage/feedback/list"
                fontIcon="bi-ticket"
              />}
              {/* GEO Fencing */}
            {permissionGeoFencing.isViewable &&
              <MenuItem
                title="Geo Fencing"
                to="/manage/geofencing"
                fontIcon="bi bi-globe"
              />}

            </MenuInnerWithSub>
          )}

          {/* {(currentUser?.userType === "SUPERADMIN" ||
            permissionGeoFencing.isViewable) && (
            <MenuInnerWithSub
              title="Settings"
              to="/settings"
              menuPlacement="bottom-start"
              menuTrigger={`{default:'click', lg: 'hover'}`}
              isMega= {false}
            >
          
              <MenuItem
                title="Integration"
                to="/settings/firebase"
                fontIcon="bi bi-intersect"
              />
             
            </MenuInnerWithSub>
          )} */}

          
        </>
      )}
      {/* {(permissionVideos.isViewable || permissionSeries.isViewable || permissionLiveTV.isViewable ||
            permissionLibrary || permissionVideoLayout || permissionEncoding.isViewable) && ( 
          <MenuItem
            title={intl.formatMessage({ id: "MENU.VIDEOS" })}
            to="/content"
          />)} */}
      {/* {(permissionAlbums.isViewable || permissionPodcasts.isViewable || 
            permissionPlayist.isViewable || permissionAudioGenres.isViewable || permissionAudioLayout.isViewable) && (
          <MenuItem
            title={intl.formatMessage({ id: "MENU.AUDIOS" })}
            to="/audio"
          />)} */}
      {/* {(permissionCustomerAnalystics.isViewable || permissionContentAnalystics.isViewable || permissionAudioAnalystics.isViewable) && (
          <MenuItem
            title={intl.formatMessage({ id: "MENU.ANALYTICS" })}
            to="/analytics"
          />)} */}
      {/* {(permissionPlans.isViewable || permissionTransaction.isViewable) && (
           <MenuItem
            title={intl.formatMessage({ id: "MENU.MONETIZATION" })}
            to="/monetization"
          />)} */}
      {/* {(permission_Content_Settings || permission_Admin || permission_Broadcast || 
            permissionUserManagement.isViewable || permissionFeedbacks.isViewable) &&  (
          <MenuItem
            title={intl.formatMessage({ id: "MENU.MANAGE" })}
            to="/manage"
          />
          )} */}
      {/* {((currentUser?.userType === 'SUPERADMIN') || (permissionGeoFencing.isViewable)) && 
          <MenuItem
            title={'Settings'}
            to="/settings"
          />
          } */}
      {/* {(location.pathname === "/content" || 
        (location.pathname.includes("/content") )) && (
        <>
          <MenuInnerWithPrimaryMenu
            title="Content"
            to="/content"
            menuPlacement="bottom-start"
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            {(permissionVideos.isViewable) && (
            <MenuItem to="/content/video" title="Video" /> )}
            {(permissionSeries.isViewable) && (
            <MenuItem to="/content/playlist" title="Series" /> )}
            {(permissionLiveTV.isViewable) && (
            <MenuItem to="/content/livestream" title="Live TV" />)}
            {(permissionLibrary.isViewable) && (
            <MenuItem to="/content/library" title="Library" />)}
            {(permissionEncoding.isViewable) && (
            <MenuItem to="/content/encoding" title="Encoding" />)}
            {(permissionVideoLayout.isViewable) && (
            <MenuItem to="/content/layout/general-layout" title="Layout" />)} 
            {/* <MenuItem
              title={intl.formatMessage({ id: "MENU.MONETIZATION" })}
              to="content/monetization"
            /> 
          </MenuInnerWithPrimaryMenu>
        </>
      )} */}
      {/* {(location.pathname === "/audio" ||
        location.pathname.includes("/audio")) && (
        <>
          <MenuInnerWithPrimaryMenu
            title="Audio"
            to="/audio"
            menuPlacement="bottom-start"
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            {(permissionAlbums.isViewable) && (
            <MenuItem to="/audio/albums" title="Albums" />)}
            {(permissionPodcasts.isViewable) && (
            <MenuItem to="/audio/podcast" title="Podcast" />)}
            {(permissionPlayist.isViewable) && (
            <MenuItem to="/audio/playlist" title="Playlist" />)}
            {(permissionAudioGenres.isViewable) && (
            <MenuItem to="/audio/genre" title="Genre" />)}
            {(permissionAudioLayout.isViewable) && (
            <MenuItem to="/audio/layout" title="Layout" /> )}
          </MenuInnerWithPrimaryMenu>
        </>
      )} */}
      {/* {(location.pathname === "/manage" ||
        location.pathname.includes("/manage")) && (
        <>
          <MenuInnerWithPrimaryMenu
            title="Manage"
            to="/manage"
            menuPlacement="bottom-start"
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            {(permissionCategories.isViewable || permissionLanguage.isViewable || permissionGenres.isViewable ||
            permissionProdHouse.isViewable || permissionCasts.isViewable || permissionGeoRestriction.isViewable ||
            permissionContentPartner.isViewable) &&  (
            <MenuItem to="/manage/setting-contents" title="Content Settings" />)}
            {(permissionUserManagement.isViewable) && (
            <MenuItem to="/manage/users" title="User Management" />)}
            {(permissionAdminUsers.isViewable || permissionRoles.isViewable ) && (
            <MenuItem to="/manage/usermanagement" title="Admins" />)}
            {(permissionBroadcastGroups.isViewable || permissionBroadcastMessages.isViewable) && (
            <MenuItem to="/manage/notification" title="Broadcast" />)}
            {(permissionFeedbacks.isViewable) && (
            <MenuItem to="/manage/feedback" title="Feedback" />)}


            {/* <MenuItem to="/manage/production-company" title="Production House" />
            <MenuItem to="/manage/users" title="Customers" />
            <MenuItem to="/manage/usermanagement" title="Admins" />
            <MenuItem to="/manage/layout" title="Layout" /> 
            <MenuItem to="/manage/notification" title="Notification" /> 
          </MenuInnerWithPrimaryMenu>
        </>
      )} */}
      {/* {(location.pathname === "/settings" ||
        location.pathname.includes("/settings")) && (
        <>
          <MenuInnerWithPrimaryMenu
            title="Settings"
            to="/settings"
            menuPlacement="bottom-start"
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
           {currentUser?.userType === 'SUPERADMIN' && 
             <MenuItem to="/settings/firebase" title="Integration" />}
           {(permissionGeoFencing.isViewable) && (
             <MenuItem to="/manage/geofencing" title="Geo Fencing"/>)}
             {/* <MenuItem to="/settings/profile-settings/user" title="Profile Settings"/> */}
      {/* <MenuItem to="/settings/customerconfig" title="Customer Config" />
            <MenuItem to="/settings/payment" title="Payment" /> 
          </MenuInnerWithPrimaryMenu>
        </>
      )} */}

      {/* <MenuInnerWithSub
        title={intl.formatMessage({ id: "MENU.CONTENT_MANAGEMENT" })}
        to="/analytics"
        menuPlacement="bottom-start"
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem
          to="/analytics/user-analytics"
          title={intl.formatMessage({ id: "MENU.CONTENT_MANAGEMENT.VIDEOS" })}
          hasBullet={true}
        />
        <MenuItem
          to="/analytics/content-analytics"
          title={intl.formatMessage({ id: "MENU.CONTENT_MANAGEMENT.SERIES" })}
          hasBullet={true}
        />
        <MenuItem
          to="/analytics/content-analytics"
          title={intl.formatMessage({ id: "MENU.CONTENT_MANAGEMENT.LAYOUTS" })}
          hasBullet={true}
        />
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title={intl.formatMessage({ id: "MENU.CONTENT_LIBRARY" })}
        to="/content-library"
        menuPlacement="bottom-start"
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem
          to="/content-library/video-library"
          title={intl.formatMessage({
            id: "MENU.CONTENT_LIBRARY.VIDEO_LIBRARY",
          })}
          hasBullet={true}
        />
        <MenuItem
          to="/content-library/audio-library"
          title={intl.formatMessage({
            id: "MENU.CONTENT_LIBRARY.AUDIO_LIBRARY",
          })}
          hasBullet={true}
        />
      </MenuInnerWithSub>  */}
      {/* <MenuInnerWithSub
        title={intl.formatMessage({ id: "MENU.CONTENT_SETTINGS" })}
        to="/content-settings"
        menuPlacement="bottom-start"
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem
          to="/content-settings/genre"
          title={intl.formatMessage({ id: "MENU.CONTENT_SETTINGS.GENRE" })}
          hasBullet={true}
        />
        <MenuItem
          to="/content-settings/languages"
          title={intl.formatMessage({ id: "MENU.CONTENT_SETTINGS.LANGUAGES" })}
          hasBullet={true}
        />
        <MenuItem
          to="/content-settings/cast"
          title={intl.formatMessage({ id: "MENU.CONTENT_SETTINGS.CAST" })}
          hasBullet={true}
        />
        <MenuItem
          to="/content-settings/category"
          title={intl.formatMessage({ id: "MENU.CONTENT_SETTINGS.CATEGORY" })}
          hasBullet={true}
        />
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title={intl.formatMessage({ id: "MENU.MONETIZATION" })}
        to="/monetization"
        menuPlacement="bottom-start"
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem
          to="/monetization/subscription"
          title={intl.formatMessage({
            id: "MENU.MONETIZATION.SUBSCRIPTION",
          })}
          hasBullet={true}
        />
        <MenuItem
          to="/monetization/transactions"
          title={intl.formatMessage({
            id: "MENU.MONETIZATION.TRANSACTIONS",
          })}
          hasBullet={true}
        />
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title={intl.formatMessage({ id: "MENU.NOTIFICATIONS" })}
        to="/notifications"
        menuPlacement="bottom-start"
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem
          to="/notifications/app-version"
          title={intl.formatMessage({
            id: "MENU.NOTIFICATIONS.APP_VERSION",
          })}
          hasBullet={true}
        />
        <MenuItem
          to="/notifications/notification-groups"
          title={intl.formatMessage({
            id: "MENU.NOTIFICATIONS.NOTIFICATION_GROUPS",
          })}
          hasBullet={true}
        />
        <MenuItem
          to="/notifications/notifications"
          title={intl.formatMessage({
            id: "MENU.NOTIFICATIONS.NOTIFICATIONS",
          })}
          hasBullet={true}
        />
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title={intl.formatMessage({ id: "MENU.SUPPORT" })}
        to="/support"
        menuPlacement="bottom-start"
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem
          to="/support/tickets"
          title={intl.formatMessage({
            id: "MENU.SUPPORT.TICKETS",
          })}
          hasBullet={true}
        />
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title={intl.formatMessage({ id: "MENU.ADMIN" })}
        to="/admin"
        menuPlacement="bottom-start"
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem
          to="/admin/admin-users"
          title={intl.formatMessage({
            id: "MENU.ADMIN.ADMIN_USERS",
          })}
          hasBullet={true}
        />
        <MenuItem
          to="/admin/roles"
          title={intl.formatMessage({
            id: "MENU.ADMIN.ROLES",
          })}
          hasBullet={true}
        />
      </MenuInnerWithSub> */}
    </>
  );
}
