/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../app/modules/auth";
import clsx from "clsx";
import { Languages } from "./Languages";
import { toAbsoluteUrl } from "../../../helpers";
import axios from "axios";
import Swal from "sweetalert2";
import { PasswordChangeEdtModal } from '../../../../app/modules/auth/password-popup/Password-Change';

interface AuthModel {
  firstName: string;
  lastName: string;
  email: string;
  changePassword: Boolean;
  profileImage: string;
}

const PROFILE_DETAILS =
  process.env.REACT_APP_API_URL + "/users/profile";

const IMAGE_API_URL_DOWNLOAD =
  process.env.REACT_APP_API_URL + "/media/download'";

const HeaderUserMenu: FC = () => {
  const { logout, currentUser } = useAuth();
  const [itemId, setItemId] = useState(false)
  const [profileData, setProfileData] = useState<AuthModel | undefined>()
  const [passwordChange, setPasswordChange] = useState(false)
  const [imageURL, setImageURL] = useState("");
  const getData = () => {
    try {
      axios({
        method: "get",
        url: `${PROFILE_DETAILS}`,
      }).then(function (response) {
        setProfileData(response.data.data)
        setPasswordChange(response.data.data.changePassword);
        getImage(response.data.data.profileImage);
        sessionStorage.setItem('USER_ROLE_ID', response.data.data.roleId);
        sessionStorage.setItem('USER_PROFILE_IMG', response.data.data.profileImage);
        // console.log("ROLE_ID:", response.data.data.roleId)

      });
    } catch (error) {
      console.log(error)
    }
  };

  const getImage = async (value) => {
    if (value) {
      axios({
        method: "get",
        url: `${IMAGE_API_URL_DOWNLOAD}?name=${value}`,
        responseType: "arraybuffer",
      }).then(function (response) {
        const imageUrl = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        if (imageUrl) {
          setImageURL(`data:;base64,${imageUrl}`);
        }else {
          setImageURL(toAbsoluteUrl("/media/svg/files/blank-image.svg"));
        }
      });
    } 
  };

  useEffect(() => getData(), [])
  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='symbol symbol-50px me-5'>
            {imageURL ?
            <img alt='Logo' src={imageURL} />
            :
              <div
              className={clsx(
                "symbol-label fs-3",
                `bg-light-primary `,
                `text-capitalize`
              )}
            >
              {profileData && profileData.firstName.charAt(0).toUpperCase() + profileData.lastName.charAt(0).toUpperCase()}
            </div>
}
            </div>

            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>
                {profileData && (profileData.firstName + ' ' + profileData.lastName)}
                {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
              </div>
              <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                {profileData && (profileData.email)}
              </a>
            </div>
          </div>
        </div>

        <div className='separator my-2'></div>

        <div className='menu-item px-5'>
          <Link to={'/dashboard'} className='menu-link px-5'>
          {/* <Link to='/manage/usermanagement/admins' className='menu-link px-5'> */}
            My Profile
          </Link>
        </div>

        {/* <Languages /> */}

        {/* <div className='menu-item px-5 my-1'>
      <Link to='/manage/usermanagement/admins' className='menu-link px-5'>
        Account Settings
      </Link>
    </div> */}
        <div className='menu-item px-5'>
          <div className='menu-link px-5 ' onClick={() => setItemId(true)}>
            Change Password
          </div>
        </div>
      {(currentUser?.userType === 'SUPERADMIN') && (
        <div className='menu-item px-5'>
        <Link to={'/settings/firebase'} className='menu-link px-5'>
            Settings
          </Link>
        </div>)}

        <div className='menu-item px-5'>
          <a onClick={() => {
            logout();
            window.location.reload();
          }} className='menu-link px-5'>
            Sign Out
          </a>
        </div>
      </div>
      {(itemId === true || passwordChange) && <PasswordChangeEdtModal setItemId={setItemId} setPasswordChange={setPasswordChange} />}
    </>
  );
};

export { HeaderUserMenu };
